import React from 'react';
import AlunosCandidatos from './components/Pages/Main';
import Resultado from './components/Pages/Resultados';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Cards from './components/Cards/Cards';
import { Modal } from '@mui/material';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<AlunosCandidatos />} exact />
          <Route path="/resultado" element={<Resultado />} exact />
          <Route path="/cards" element={<Cards />} exact />
          <Route path="/modal" element={<Modal />} exact />
        </Routes>
      </div>
    </ Router>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import './Resultados.scss';
import Mapa from '../Mapa/Mapa';
import Subtitles from '../Subtitles/Subtitles';

import { FaBirthdayCake } from 'react-icons/fa';
import { BsGenderFemale, BsGenderMale, BsGenderTrans, BsGeoAltFill, BsCheck2Square, BsListOl } from "react-icons/bs";

import TURMA2122 from '../../assets/TURMA2122.png';
import E2018 from '../../assets/E2018.png';
import NE2018 from '../../assets/NE2018.png';
import E2019 from '../../assets/E2019.png';
import NE2019 from '../../assets/NE2019.png';
import ELEITO2022 from '../../assets/ELEITO2022.png';
import NELEITO2022 from '../../assets/NELEITO2022.png';
import SEGUNDOTURNO from '../../assets/SEGUNDOTURNO.png';
import PENDENTE from '../../assets/PENDENTE.png';

// MUI
import Skeleton from '@mui/material/Skeleton';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Cards from '../Cards/Cards';

function Categoria(props) {
    const categoria = props.categoria;

    switch (categoria) {
        case 'NÃO ELEITOS 2018':
            return <img src={NE2018} style={{ width: '39px', height: '14px' }} alt='NE2018'></img>;
        case '21/22':
            return <img src={TURMA2122} style={{ width: '38px', height: '14px' }} alt='21/22'></img>;
        case 'NÃO ELEITOS 19/20':
            return <img src={NE2019} style={{ width: '54px', height: '14px' }} alt='NE2019'></img>;
        case 'ELEITOS 19/20':
            return <img src={E2019} style={{ width: '46px', height: '14px' }} alt='E2019'></img>;
        case 'ELEITOS 2018':
            return <img src={E2018} style={{ width: '28px', height: '14px' }} alt='E2018'></img>;
        default:
            return <p>Não encontrada categoria</p>;
    }
}

function Eleito(props) {
    const resultado = props.resultado;

    switch (resultado) {
        case 'ELEITO':
            return <li><img src={ELEITO2022} style={{ width: '72px', height: '19px' }} alt='ELEITO 2022'></img></li>;
        case 'ELEITO POR MÉDIA':
            return <li><img src={ELEITO2022} style={{ width: '75px', height: '19px' }} alt='ELEITO 2022'></img></li>;
        case 'ELEITO POR QP':
            return <li><img src={ELEITO2022} style={{ width: '75px', height: '19px' }} alt='ELEITO 2022'></img></li>;
        case 'NÃO ELEITO':
            return <li><img src={NELEITO2022} style={{ width: '95px', height: '17px' }} alt='NÃO ELEITO'></img></li>;
        case 'SUPLENTE':
            return <li><img src={NELEITO2022} style={{ width: '95px', height: '17px' }} alt='1º SUPLENTE'></img></li>;
        case '2º TURNO':
            return <li><img src={SEGUNDOTURNO} style={{ width: '66px', height: '17px' }} alt='2º TURNO'></img></li>;
        case '#':
            return <li><img src={PENDENTE} style={{ width: '66px', height: '17px' }} alt='RESULTADO PENDENTE'></img></li>;
        case '':
            return <li><img src={PENDENTE} style={{ width: '66px', height: '17px' }} alt='RESULTADO PENDENTE'></img></li>;
        default:
            return <li><img src={PENDENTE} style={{ width: '66px', height: '17px' }} alt='RESULTADO PENDENTE'></img></li>;
    }
};

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35, size: 40, thickness: 2 }}>
                <Typography variant="body3" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

function Resultados() {
    const [progress, setProgress] = React.useState(0);
    const [alunosList, setAlunosList] = useState([]);

    useEffect(() => {
        api.get("/api/alunos/eleitos/totais")
            .then((response) => {
                setAlunosList(response.data)
            })
            .catch((err) => {
                console.error("Ocorreu um erro: " + err);
            });

    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 0));
        }, 800);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <div className='eleitos-wrapper'>
            <Subtitles />
            <Cards resultados={alunosList} />
            <div className='container-eleitos'>
                <div className='search-bar'>
                    <div className='container-eleitos-cand'>
                        {
                            (alunosList.length !== 0) ?
                                (alunosList.alunos_eleitos?.map(alunos => (
                                    <ul>
                                        <li className='li-profile-eleitos'>
                                            <img className='cand-img' src={alunos.foto_url} alt='candidate'></img>
                                        </li>
                                        <li className='cand-bar-information'>
                                            <h4>Apuração:</h4>
                                            <Box sx={{ width: '100%' }}>
                                            <Stack sx={{ color: '#38E069' }}>
                                                <LinearProgressWithLabel color="inherit" value={alunos.percentual_totalização} />
                                                </Stack>
                                            </Box>
                                        </li>
                                        <li className='cand-details'>
                                            <b>{alunos.nome_urna}</b>
                                            <b>{alunos.cargo}</b>
                                            <br></br> {alunos.partido}</li>
                                        <li className='cand-information'>{alunos.gênero >= "MASCULINO" ? (<BsGenderMale size={13} />) : alunos.gênero >= "FEMININO" ? (<BsGenderFemale size={13} />) : (<BsGenderTrans size={13} />)}
                                            <BsCheck2Square size={13} color='#3A5EDD' />{alunos.raça}
                                            <BsGeoAltFill size={13} color='#3A5EDD' />{alunos.estado}
                                            <FaBirthdayCake size={13} color='#3A5EDD' />{alunos.idade}
                                            <Categoria size={13} categoria={alunos.categoria}></Categoria>
                                        </li>
                                        <li className='cand-more-information'>
                                            <BsListOl size={16} color='#3A5EDD' />{alunos.posição}º / {alunos.num_vagas_disputadas}
                                            <Eleito resultado={alunos.resultado}></Eleito>
                                            VOTOS {alunos.num_votos}
                                            <b>{alunos.percentual_votos}%</b>
                                        </li>
                                    </ul>
                                ))
                                ) : (
                                    <div className='loading-results-list'>
                                        <Skeleton variant="rectangular" animation="pulse" width={540} height={90} /><br></br>
                                        <Skeleton variant="rectangular" animation="pulse" width={540} height={90} /><br></br>
                                        <Skeleton variant="rectangular" animation="pulse" width={540} height={90} /><br></br>
                                        <Skeleton variant="rectangular" animation="pulse" width={540} height={90} />
                                    </div>
                                )
                        }
                    </div>
                </div>
            </div>
            <Mapa />
        </div>

    )
}

export default Resultados;
import React, { useEffect, useState } from "react";
import api from "../../services/api";
import './Cards.scss';

import FederalIcone from '../../assets/FederalIcone.png';
import SenadoIcone from '../../assets/SenadoIcone.png';

import { FaVoteYea, FaBirthdayCake } from 'react-icons/fa';
import { BsPeopleFill, BsFillCheckSquareFill, BsGeoAltFill, BsFlagFill, BsCardList, BsBank, BsGenderMale, BsGenderFemale, BsGenderTrans, BsGeoAlt, BsCheck2Square, BsListOl } from "react-icons/bs";

import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Modal from '@mui/material/Modal';

import TURMA2122 from '../../assets/TURMA2122.png';
import E2018 from '../../assets/E2018.png';
import NE2018 from '../../assets/NE2018.png';
import E2019 from '../../assets/E2019.png';
import NE2019 from '../../assets/NE2019.png';
import ELEITO2022 from '../../assets/ELEITO2022.png';
import NELEITO2022 from '../../assets/NELEITO2022.png';
import SEGUNDOTURNO from '../../assets/SEGUNDOTURNO.png';
import PENDENTE from '../../assets/PENDENTE.png';


function Categoria(props) {
    const categoria = props.categoria;

    switch (categoria) {
        case 'NÃO ELEITOS 2018':
            return <img src={NE2018} style={{ width: '39px', height: '14px' }} alt='NE2018'></img>;
        case '21/22':
            return <img src={TURMA2122} style={{ width: '38px', height: '14px' }} alt='21/22'></img>;
        case 'NÃO ELEITOS 19/20':
            return <img src={NE2019} style={{ width: '54px', height: '14px' }} alt='NE2019'></img>;
        case 'ELEITOS 19/20':
            return <img src={E2019} style={{ width: '46px', height: '14px' }} alt='E2019'></img>;
        case 'ELEITOS 2018':
            return <img src={E2018} style={{ width: '28px', height: '14px' }} alt='E2018'></img>;
        default:
            return <p>Não encontrada categoria</p>;
    }
}

function Eleito(props) {
    const resultado = props.resultado;

    switch (resultado) {
        case 'ELEITO':
            return <li><img src={ELEITO2022} style={{ width: '72px', height: '19px' }} alt='ELEITO 2022'></img></li>;
        case 'ELEITO POR MÉDIA':
            return <li><img src={ELEITO2022} style={{ width: '75px', height: '19px' }} alt='ELEITO 2022'></img></li>;
        case 'ELEITO POR QP':
            return <li><img src={ELEITO2022} style={{ width: '75px', height: '19px' }} alt='ELEITO 2022'></img></li>;
        case 'NÃO ELEITO':
            return <li><img src={NELEITO2022} style={{ width: '95px', height: '17px' }} alt='NÃO ELEITO'></img></li>;
        case 'SUPLENTE':
            return <li><img src={NELEITO2022} style={{ width: '95px', height: '17px' }} alt='1º SUPLENTE'></img></li>;
        case '2º TURNO':
            return <li><img src={SEGUNDOTURNO} style={{ width: '66px', height: '17px' }} alt='2º TURNO'></img></li>;
        case '#':
            return <li><img src={PENDENTE} style={{ width: '66px', height: '17px' }} alt='RESULTADO PENDENTE'></img></li>;
        case '':
            return <li><img src={PENDENTE} style={{ width: '66px', height: '17px' }} alt='RESULTADO PENDENTE'></img></li>;
        default:
            return <li><img src={PENDENTE} style={{ width: '66px', height: '17px' }} alt='RESULTADO PENDENTE'></img></li>;
    }
};

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35, size: 40, thickness: 2 }}>
                <Typography variant="body3" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <Stack sx={{ color: '#38E069' }}>
                <CircularProgress variant="determinate" {...props} />
                <Box
                    sx={{
                        top: 0,
                        left: 20,
                        bottom: 0,
                        right: 0,
                        fontSize: 12,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="caption" component="div" color="text.secondary">
                        {`${Math.round(props.value)}%`}
                    </Typography>
                </Box></Stack>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    height: 400,
    bgcolor: 'background.paper',
    border: '1px solid #FFF',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
};

function Cards(props) {

    const [cargo, setCargo] = useState([]);
    const [genero, setGenero] = useState([]);
    const [progress, setProgress] = useState(10);
    const [apuracao, setApuracao] = useState([]);

    const [openEstadual, setOpenEstadual] = useState(false);
    const handleOpenEstadual = () => setOpenEstadual(true);
    const handleCloseEstadual = () => setOpenEstadual(false);

    const [openDistrital, setOpenDistrital] = useState(false);
    const handleOpenDistrital = () => setOpenDistrital(true);
    const handleCloseDistrital = () => setOpenDistrital(false);

    const [openFederal, setOpenFederal] = useState(false);
    const handleOpenFederal = () => setOpenFederal(true);
    const handleCloseFederal = () => setOpenFederal(false);

    const [openSenado, setOpenSenado] = useState(false);
    const handleOpenSenado = () => setOpenSenado(true);
    const handleCloseSenado = () => setOpenSenado(false);

    const [openViceGov, setOpenViceGov] = useState(false);
    const handleOpenViceGov = () => setOpenViceGov(true);
    const handleCloseViceGov = () => setOpenViceGov(false);

    const [openGovernador, setOpenGovernador] = useState(false);
    const handleOpenGovernador = () => setOpenGovernador(true);
    const handleCloseGovernador = () => setOpenGovernador(false);

    const [openVicePres, setOpenVicePres] = useState(false);
    const handleOpenVicePres = () => setOpenVicePres(true);
    const handleCloseVicePres = () => setOpenVicePres(false);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
        }, 800);
        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        api.get("/api/alunos/eleitos/cargo/*")
            .then((response) => {
                setCargo(response.data);
            })
            .catch((err) => {
                console.error("Ocorreu um erro: " + err);
            });

    }, []);

    useEffect(() => {
        api.get("/api/alunos/totais")
            .then((response) => {
                setApuracao(response.data)
            })
            .catch((err) => {
                console.error("Ocorreu um erro: " + err);
            });
    }, []);

    useEffect(() => {
        api.get("/api/alunos/eleitos/totais/genero/*")
            .then((response) => {
                setGenero(response.data);
            })
            .catch((err) => {
                console.error("Ocorreu um erro: " + err);
            });

    }, []);

    return (
        <div className="cargos-cards">
            <div className="content">
                <div className="box"><BsPeopleFill color='#3A5EDD' size={25} /><b className="cards-b">{props.resultados.alunos}</b> Alunos</div>
                <div className="box"><BsFillCheckSquareFill color='#3A5EDD' size={25} /><b className="cards-b">{props.resultados.eleitos}</b> Eleitos</div>
            </div>
            <div className="content-row-2">
                <div className="box"><BsGeoAltFill color='#3A5EDD' size={25} /><b className="cards-b">{props.resultados.estados}</b> Estados</div>
                <div className="box"><BsFlagFill color='#3A5EDD' size={25} /><b className="cards-b">{props.resultados.partidos}</b> Partidos</div>
            </div>
            <div className="content-row-3">
                <div className="box"><BsCardList color='#3A5EDD' size={25} /><b className="cards-b">0</b> 1º Suplente</div>
                
                <div className="box"><BsBank color='#3A5EDD' size={25} /> <b className="cards-b">{cargo?.deputado_estadual?.eleitos}</b>
                    <button onClick={handleOpenEstadual} disabled={cargo?.deputado_estadual?.eleitos == 0}>Estadual</button>
                    <Modal open={openEstadual} onClose={handleCloseEstadual} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                <BsBank color='#3A5EDD' size={24} /> Resultado Eleição Estadual
                                <hr></hr>
                                <div className='container-resultados'>
                                    <div className='container-alunos-resultados'>
                                        {
                                            (props.resultados.length !== 0) ?
                                                (props.resultados.alunos_eleitos?.filter(filtro => filtro.cargo === "DEPUTADO ESTADUAL").map(alunos => (
                                                    <ul>
                                                        <li className='li-profile-eleitos'>
                                                            <img className='cand-img' src={alunos.foto_url} alt='candidate'></img>
                                                        </li>
                                                        <li className='cand-bar-information'>
                                                            <h4>Apuração:</h4>
                                                            <Box sx={{ width: '100%' }}>
                                                                <Stack sx={{ color: '#38E069' }}>
                                                                    <LinearProgressWithLabel color="inherit" value={alunos.percentual_totalização} />
                                                                </Stack>
                                                            </Box>
                                                        </li>
                                                        <li className='cand-details'>
                                                            <b>{alunos.nome_urna}</b>
                                                            <b>{alunos.cargo}</b>
                                                            <br></br> {alunos.partido}</li>
                                                        <li className='cand-information'>{alunos.gênero >= "MASCULINO" ? (<BsGenderMale />) : alunos.gênero >= "FEMININO" ? (<BsGenderFemale />) : (<BsGenderTrans />)}
                                                            <BsCheck2Square color='#3A5EDD' />{alunos.raça}
                                                            <BsGeoAlt color='#3A5EDD' />{alunos.estado}
                                                            <FaBirthdayCake color='#3A5EDD' />{alunos.idade}
                                                            <Categoria categoria={alunos.categoria}></Categoria>
                                                        </li>
                                                        <li className='aluno-more-information'>
                                                            <BsListOl color='#3A5EDD' />{alunos.posição}º / {alunos.num_vagas_disputadas}
                                                            <Eleito resultado={alunos.resultado}></Eleito>
                                                            VOTOS {alunos.num_votos}
                                                            <b>{alunos.percentual_votos}%</b>
                                                        </li>
                                                    </ul>
                                                ))
                                                ) : (
                                                    <div className='loading-results-list'>
                                                        Sem resultados a serem exibidos.
                                                    </div>
                                                )
                                        }
                                    </div>
                                </div>
                            </Typography>
                        </Box>
                    </Modal>
                </div>
            </div>
            <div className="content-row-4">
                <div className="box"><svg width="27" height="23" viewBox="0 0 27 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.37481 10.2232H7.875V20.2893H10.125V11.4814H12.375V20.2893H14.625V11.4814H16.875V20.2893H19.125V10.2232H20.6246C21.0386 10.2232 21.375 9.76012 21.375 9.343C21.375 9.09072 21.2507 8.80635 21.0628 8.6692L21.0639 8.63649L21.0521 8.61321L21.1275 8.59245L14.0625 4.52259V3.33227C15.1875 3.99852 16.2849 2.85099 18 3.54115V0.912002C16.2838 0.222473 15.1875 1.36938 14.0625 0.70313V0.534523C14.0625 0.32628 13.7076 0.157043 13.5 0.157043C13.2924 0.157043 12.9375 0.32628 12.9375 0.534523V4.52259L5.87306 8.58427L6.0255 8.59748L6.1875 8.6044V8.60566C5.625 8.74281 5.751 9.09009 5.751 9.34237C5.75044 9.76012 5.96081 10.2232 6.37481 10.2232V10.2232ZM7.3125 20.9184L5.625 22.8058H21.375L19.6875 20.9184H7.3125ZM19.6875 10.8523V20.2893H20.1853L20.4053 20.6372L21.9049 22.2094L22.4353 22.8058H27V10.8523H19.6875ZM23.0625 18.4019H21.375V16.5145H23.0625V18.4019ZM23.0625 14.6271H21.375V12.7397H23.0625V14.6271ZM25.3125 18.4019H24.1875V16.5145H25.3125V18.4019ZM25.3125 14.6271H24.1875V12.7397H25.3125V14.6271ZM6.81413 20.2893H7.3125V10.8523H0V22.8058H4.56413L5.094 22.2723L6.59419 20.6366L6.81413 20.2893V20.2893ZM2.8125 18.4019H1.6875V16.5145H2.8125V18.4019ZM2.8125 14.6271H1.6875V12.7397H2.8125V14.6271ZM5.625 18.4019H3.9375V16.5145H5.625V18.4019ZM5.625 14.6271H3.9375V12.7397H5.625V14.6271Z" fill="#3A5EDD" />
                </svg> <b className="cards-b">{cargo?.vice_governador?.eleitos}</b>
                    <button onClick={handleOpenViceGov} disabled={cargo?.vice_governador?.eleitos == 0}>Vice Governador</button>
                    <Modal open={openViceGov} onClose={handleCloseViceGov} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                <svg width="27" height="23" viewBox="0 0 27 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.37481 10.2232H7.875V20.2893H10.125V11.4814H12.375V20.2893H14.625V11.4814H16.875V20.2893H19.125V10.2232H20.6246C21.0386 10.2232 21.375 9.76012 21.375 9.343C21.375 9.09072 21.2507 8.80635 21.0628 8.6692L21.0639 8.63649L21.0521 8.61321L21.1275 8.59245L14.0625 4.52259V3.33227C15.1875 3.99852 16.2849 2.85099 18 3.54115V0.912002C16.2838 0.222473 15.1875 1.36938 14.0625 0.70313V0.534523C14.0625 0.32628 13.7076 0.157043 13.5 0.157043C13.2924 0.157043 12.9375 0.32628 12.9375 0.534523V4.52259L5.87306 8.58427L6.0255 8.59748L6.1875 8.6044V8.60566C5.625 8.74281 5.751 9.09009 5.751 9.34237C5.75044 9.76012 5.96081 10.2232 6.37481 10.2232V10.2232ZM7.3125 20.9184L5.625 22.8058H21.375L19.6875 20.9184H7.3125ZM19.6875 10.8523V20.2893H20.1853L20.4053 20.6372L21.9049 22.2094L22.4353 22.8058H27V10.8523H19.6875ZM23.0625 18.4019H21.375V16.5145H23.0625V18.4019ZM23.0625 14.6271H21.375V12.7397H23.0625V14.6271ZM25.3125 18.4019H24.1875V16.5145H25.3125V18.4019ZM25.3125 14.6271H24.1875V12.7397H25.3125V14.6271ZM6.81413 20.2893H7.3125V10.8523H0V22.8058H4.56413L5.094 22.2723L6.59419 20.6366L6.81413 20.2893V20.2893ZM2.8125 18.4019H1.6875V16.5145H2.8125V18.4019ZM2.8125 14.6271H1.6875V12.7397H2.8125V14.6271ZM5.625 18.4019H3.9375V16.5145H5.625V18.4019ZM5.625 14.6271H3.9375V12.7397H5.625V14.6271Z" fill="#3A5EDD" />
                                </svg> Resultado Eleição Vice-Governador
                                <hr></hr>
                                <div className='container-resultados'>
                                    <div className='container-alunos-resultados'>
                                        {
                                            (props.resultados.length !== 0) ?
                                                (props.resultados.alunos_eleitos?.filter(filtro => filtro.cargo === "VICE GOVERNADOR").map(alunos => (
                                                    <ul>
                                                        <li className='li-profile-eleitos'>
                                                            <img className='cand-img' src={alunos.foto_url} alt='candidate'></img>
                                                        </li>
                                                        <li className='cand-bar-information'>
                                                            <h4>Apuração:</h4>
                                                            <Box sx={{ width: '100%' }}>
                                                                <Stack sx={{ color: '#38E069' }}>
                                                                    <LinearProgressWithLabel color="inherit" value={alunos.percentual_totalização} />
                                                                </Stack>
                                                            </Box>
                                                        </li>
                                                        <li className='cand-details'>
                                                            <b>{alunos.nome_urna}</b>
                                                            <b>{alunos.cargo}</b>
                                                            <br></br> {alunos.partido}</li>
                                                        <li className='cand-information'>{alunos.gênero >= "MASCULINO" ? (<BsGenderMale />) : alunos.gênero >= "FEMININO" ? (<BsGenderFemale />) : (<BsGenderTrans />)}
                                                            <BsCheck2Square color='#3A5EDD' />{alunos.raça}
                                                            <BsGeoAlt color='#3A5EDD' />{alunos.estado}
                                                            <FaBirthdayCake color='#3A5EDD' />{alunos.idade}
                                                            <Categoria categoria={alunos.categoria}></Categoria>
                                                        </li>
                                                        <li className='aluno-more-information'>
                                                            <BsListOl color='#3A5EDD' />{alunos.posição}º / {alunos.num_vagas_disputadas}
                                                            <Eleito resultado={alunos.resultado}></Eleito>
                                                            VOTOS {alunos.num_votos}
                                                            <b>{alunos.percentual_votos}%</b>
                                                        </li>
                                                    </ul>
                                                ))
                                                ) : (
                                                    <div className='loading-results-list'>
                                                        Sem resultados a serem exibidos.
                                                    </div>
                                                )
                                        }

                                    </div>
                                </div>
                            </Typography>
                        </Box>
                    </Modal>
                </div>
                <div className="box"><svg width="27" height="23" viewBox="0 0 27 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.37481 10.2232H7.875V20.2893H10.125V11.4814H12.375V20.2893H14.625V11.4814H16.875V20.2893H19.125V10.2232H20.6246C21.0386 10.2232 21.375 9.76012 21.375 9.343C21.375 9.09072 21.2507 8.80635 21.0628 8.6692L21.0639 8.63649L21.0521 8.61321L21.1275 8.59245L14.0625 4.52259V3.33227C15.1875 3.99852 16.2849 2.85099 18 3.54115V0.912002C16.2838 0.222473 15.1875 1.36938 14.0625 0.70313V0.534523C14.0625 0.32628 13.7076 0.157043 13.5 0.157043C13.2924 0.157043 12.9375 0.32628 12.9375 0.534523V4.52259L5.87306 8.58427L6.0255 8.59748L6.1875 8.6044V8.60566C5.625 8.74281 5.751 9.09009 5.751 9.34237C5.75044 9.76012 5.96081 10.2232 6.37481 10.2232V10.2232ZM7.3125 20.9184L5.625 22.8058H21.375L19.6875 20.9184H7.3125ZM19.6875 10.8523V20.2893H20.1853L20.4053 20.6372L21.9049 22.2094L22.4353 22.8058H27V10.8523H19.6875ZM23.0625 18.4019H21.375V16.5145H23.0625V18.4019ZM23.0625 14.6271H21.375V12.7397H23.0625V14.6271ZM25.3125 18.4019H24.1875V16.5145H25.3125V18.4019ZM25.3125 14.6271H24.1875V12.7397H25.3125V14.6271ZM6.81413 20.2893H7.3125V10.8523H0V22.8058H4.56413L5.094 22.2723L6.59419 20.6366L6.81413 20.2893V20.2893ZM2.8125 18.4019H1.6875V16.5145H2.8125V18.4019ZM2.8125 14.6271H1.6875V12.7397H2.8125V14.6271ZM5.625 18.4019H3.9375V16.5145H5.625V18.4019ZM5.625 14.6271H3.9375V12.7397H5.625V14.6271Z" fill="#3A5EDD" />
                </svg> <b className="cards-b">{cargo?.governador?.eleitos}</b>
                    <button onClick={handleOpenGovernador} disabled={cargo?.governador?.eleitos == 0}>Governador</button>
                    <Modal open={openGovernador} onClose={handleCloseGovernador} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                <svg width="27" height="23" viewBox="0 0 27 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.37481 10.2232H7.875V20.2893H10.125V11.4814H12.375V20.2893H14.625V11.4814H16.875V20.2893H19.125V10.2232H20.6246C21.0386 10.2232 21.375 9.76012 21.375 9.343C21.375 9.09072 21.2507 8.80635 21.0628 8.6692L21.0639 8.63649L21.0521 8.61321L21.1275 8.59245L14.0625 4.52259V3.33227C15.1875 3.99852 16.2849 2.85099 18 3.54115V0.912002C16.2838 0.222473 15.1875 1.36938 14.0625 0.70313V0.534523C14.0625 0.32628 13.7076 0.157043 13.5 0.157043C13.2924 0.157043 12.9375 0.32628 12.9375 0.534523V4.52259L5.87306 8.58427L6.0255 8.59748L6.1875 8.6044V8.60566C5.625 8.74281 5.751 9.09009 5.751 9.34237C5.75044 9.76012 5.96081 10.2232 6.37481 10.2232V10.2232ZM7.3125 20.9184L5.625 22.8058H21.375L19.6875 20.9184H7.3125ZM19.6875 10.8523V20.2893H20.1853L20.4053 20.6372L21.9049 22.2094L22.4353 22.8058H27V10.8523H19.6875ZM23.0625 18.4019H21.375V16.5145H23.0625V18.4019ZM23.0625 14.6271H21.375V12.7397H23.0625V14.6271ZM25.3125 18.4019H24.1875V16.5145H25.3125V18.4019ZM25.3125 14.6271H24.1875V12.7397H25.3125V14.6271ZM6.81413 20.2893H7.3125V10.8523H0V22.8058H4.56413L5.094 22.2723L6.59419 20.6366L6.81413 20.2893V20.2893ZM2.8125 18.4019H1.6875V16.5145H2.8125V18.4019ZM2.8125 14.6271H1.6875V12.7397H2.8125V14.6271ZM5.625 18.4019H3.9375V16.5145H5.625V18.4019ZM5.625 14.6271H3.9375V12.7397H5.625V14.6271Z" fill="#3A5EDD" />
                                </svg> Resultado Eleição Governador
                                <hr></hr>
                                <div className='container-resultados'>
                                    <div className='container-alunos-resultados'>
                                        {
                                            (props.resultados.length !== 0) ?
                                                (props.resultados.alunos_eleitos?.filter(filtro => filtro.cargo === "GOVERNADOR").map(alunos => (
                                                    <ul>
                                                        <li className='li-profile-eleitos'>
                                                            <img className='cand-img' src={alunos.foto_url} alt='candidate'></img>
                                                        </li>
                                                        <li className='cand-bar-information'>
                                                            <h4>Apuração:</h4>
                                                            <Box sx={{ width: '100%' }}>
                                                                <Stack sx={{ color: '#38E069' }}>
                                                                    <LinearProgressWithLabel color="inherit" value={alunos.percentual_totalização} />
                                                                </Stack>
                                                            </Box>
                                                        </li>
                                                        <li className='cand-details'>
                                                            <b>{alunos.nome_urna}</b>
                                                            <b>{alunos.cargo}</b>
                                                            <br></br> {alunos.partido}</li>
                                                        <li className='cand-information'>{alunos.gênero >= "MASCULINO" ? (<BsGenderMale />) : alunos.gênero >= "FEMININO" ? (<BsGenderFemale />) : (<BsGenderTrans />)}
                                                            <BsCheck2Square color='#3A5EDD' />{alunos.raça}
                                                            <BsGeoAlt color='#3A5EDD' />{alunos.estado}
                                                            <FaBirthdayCake color='#3A5EDD' />{alunos.idade}
                                                            <Categoria categoria={alunos.categoria}></Categoria>
                                                        </li>
                                                        <li className='aluno-more-information'>
                                                            <BsListOl color='#3A5EDD' />{alunos.posição}º / {alunos.num_vagas_disputadas}
                                                            <Eleito resultado={alunos.resultado}></Eleito>
                                                            VOTOS {alunos.num_votos}
                                                            <b>{alunos.percentual_votos}%</b>
                                                        </li>
                                                    </ul>
                                                ))
                                                ) : (
                                                    <div className='loading-results-list'>
                                                        Sem resultados a serem exibidos.
                                                    </div>
                                                )
                                        }

                                    </div>
                                </div>
                            </Typography>
                        </Box>
                    </Modal>
                </div>
            </div>

            <div className="generos-cards">
                <div className="content-generos">
                    <div className="content-eleitos-homens"><BsGenderMale color='#3A5EDD' size={25} /><b className="cards-b">{genero?.MASCULINO?.eleitos}</b> Homens
                        <div className="content-media-idade-homens">Média de idade <br></br><b className="cards-b">{genero?.MASCULINO?.media_idade} anos </b></div>
                        <div className="content-media-votos-homens">Média de votos <br></br><b className="cards-b">{genero?.MASCULINO?.media_votos} mil </b></div>
                        <div className="content-progress-homens"><CircularProgressWithLabel size="3.5rem" color="inherit" value={props.resultados.percent_homens} /></div>
                    </div>
                    <div className="content-eleitas-mulheres"><BsGenderFemale color='#3A5EDD' size={25} /><b className="cards-b">{genero?.FEMININO?.eleitos}</b> Mulheres</div>
                    <div className="content-media-idade-mulheres">Média de idade <br></br><b className="cards-b">{genero?.FEMININO?.media_idade} anos </b></div>
                    <div className="content-media-votos-mulheres">Média de votos <br></br><b className="cards-b">{genero?.FEMININO?.media_votos} mil </b></div>
                    <div className="content-progress-mulheres"><CircularProgressWithLabel size="3.5rem" color="inherit" value={props.resultados.percent_mulheres} /></div>
                </div>
            </div>

            <div className="card-distrital">
                <div class="box"><svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.4615 6.85714H12.3077V1.71429L10 0L7.69231 1.71429V6.85714H1.53846L0 8.57143V10.2857H20V8.57143L18.4615 6.85714ZM10 2.57143C10.6154 2.57143 11.0769 3.08571 11.0769 3.94286C11.0769 4.8 10.6154 5.14286 10 5.14286C9.38462 5.14286 8.76923 4.62857 8.76923 3.77143C8.76923 3.08571 9.38462 2.57143 10 2.57143ZM18.4615 12H1.53846V18.8571L0 21.4286V24H20V21.4286L18.4615 18.8571V12ZM6.15385 21.4286H4.61538V13.7143H6.15385V21.4286ZM10.7692 21.4286H9.23077V13.7143H10.7692V21.4286ZM15.3846 21.4286H13.8462V13.7143H15.3846V21.4286Z" fill="#3A5EDD" />
                </svg> <b className="cards-b">{cargo?.deputado_distrital?.eleitos}</b>
                    <button onClick={handleOpenDistrital} disabled={cargo?.deputado_distrital?.eleitos == 0}>Distrital</button>
                    <Modal open={openDistrital} onClose={handleCloseDistrital} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.4615 6.85714H12.3077V1.71429L10 0L7.69231 1.71429V6.85714H1.53846L0 8.57143V10.2857H20V8.57143L18.4615 6.85714ZM10 2.57143C10.6154 2.57143 11.0769 3.08571 11.0769 3.94286C11.0769 4.8 10.6154 5.14286 10 5.14286C9.38462 5.14286 8.76923 4.62857 8.76923 3.77143C8.76923 3.08571 9.38462 2.57143 10 2.57143ZM18.4615 12H1.53846V18.8571L0 21.4286V24H20V21.4286L18.4615 18.8571V12ZM6.15385 21.4286H4.61538V13.7143H6.15385V21.4286ZM10.7692 21.4286H9.23077V13.7143H10.7692V21.4286ZM15.3846 21.4286H13.8462V13.7143H15.3846V21.4286Z" fill="#3A5EDD" />
                                </svg> Resultado Eleição Distrital
                                <hr></hr>
                                <div className='container-eleitos-cand'>
                                    {
                                        (props.resultados.length !== 0) ?
                                            (props.resultados.alunos_eleitos?.filter(filtro => filtro.cargo === "DEPUTADO DISTRITAL").map(alunos => (
                                                <ul>
                                                    <li className='li-profile-eleitos'>
                                                        <img className='cand-img' src={alunos.foto_url} alt='candidate'></img>
                                                    </li>
                                                    <li className='cand-bar-information'>
                                                        <h4>Apuração:</h4>
                                                        <Box sx={{ width: '100%' }}>
                                                            <Stack sx={{ color: '#38E069' }}>
                                                                <LinearProgressWithLabel color="inherit" value={alunos.percentual_totalização} />
                                                            </Stack>
                                                        </Box>
                                                    </li>
                                                    <li className='cand-details'>
                                                        <b>{alunos.nome_urna}</b>
                                                        <b>{alunos.cargo}</b>
                                                        <br></br> {alunos.partido}</li>
                                                    <li className='cand-information'>{alunos.gênero >= "MASCULINO" ? (<BsGenderMale />) : alunos.gênero >= "FEMININO" ? (<BsGenderFemale />) : (<BsGenderTrans />)}
                                                        <BsCheck2Square color='#3A5EDD' />{alunos.raça}
                                                        <BsGeoAlt color='#3A5EDD' />{alunos.estado}
                                                        <FaBirthdayCake color='#3A5EDD' />{alunos.idade}
                                                        <Categoria categoria={alunos.categoria}></Categoria>
                                                    </li>
                                                    <li className='aluno-more-information'>
                                                        <BsListOl color='#3A5EDD' />{alunos.posição}º / {alunos.num_vagas_disputadas}
                                                        <Eleito resultado={alunos.resultado}></Eleito>
                                                        VOTOS {alunos.num_votos}
                                                        <b>{alunos.percentual_votos}%</b>
                                                    </li>
                                                </ul>
                                            ))
                                            ) : (
                                                <div className='loading-results-list'>
                                                    Sem resultados a serem exibidos.
                                                </div>
                                            )
                                    }

                                </div>
                            </Typography>
                        </Box>
                    </Modal>
                </div>
            </div>
            <div className="card-federal">
                <div class="box">
                    <img src={FederalIcone} style={{ width: '40px', marginLeft: '25px' }} alt='' className='icone-cargo-federal'></img>
                    <b className="cards-b">{cargo?.deputado_federal?.eleitos}</b>
                    <button onClick={handleOpenFederal} disabled={cargo?.deputado_federal?.eleitos == 0}>Federal</button>
                    <Modal open={openFederal} onClose={handleCloseFederal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                <img src={FederalIcone} style={{ width: '40px', marginLeft: '25px' }} alt='' className='icone-cargo-federal'></img> Resultado Eleição Federal
                                <hr></hr>
                                <div className='container-resultados'>
                                    <div className='container-alunos-resultados'>
                                        {
                                            (props.resultados.length !== 0) ?
                                                (props.resultados.alunos_eleitos?.filter(filtro => filtro.cargo === "DEPUTADO FEDERAL").map(alunos => (
                                                    <ul>
                                                        <li className='li-profile-eleitos'>
                                                            <img className='cand-img' src={alunos.foto_url} alt='candidate'></img>
                                                        </li>
                                                        <li className='cand-bar-information'>
                                                            <h4>Apuração:</h4>
                                                            <Box sx={{ width: '100%' }}>
                                                                <Stack sx={{ color: '#38E069' }}>
                                                                    <LinearProgressWithLabel color="inherit" value={alunos.percentual_totalização} />
                                                                </Stack>
                                                            </Box>
                                                        </li>
                                                        <li className='cand-details'>
                                                            <b>{alunos.nome_urna}</b>
                                                            <b>{alunos.cargo}</b>
                                                            <br></br> {alunos.partido}</li>
                                                        <li className='cand-information'>{alunos.gênero >= "MASCULINO" ? (<BsGenderMale />) : alunos.gênero >= "FEMININO" ? (<BsGenderFemale />) : (<BsGenderTrans />)}
                                                            <BsCheck2Square color='#3A5EDD' />{alunos.raça}
                                                            <BsGeoAlt color='#3A5EDD' />{alunos.estado}
                                                            <FaBirthdayCake color='#3A5EDD' />{alunos.idade}
                                                            <Categoria categoria={alunos.categoria}></Categoria>
                                                        </li>
                                                        <li className='aluno-more-information'>
                                                            <BsListOl color='#3A5EDD' />{alunos.posição}º / {alunos.num_vagas_disputadas}
                                                            <Eleito resultado={alunos.resultado}></Eleito>
                                                            VOTOS {alunos.num_votos}
                                                            <b>{alunos.percentual_votos}%</b>
                                                        </li>
                                                    </ul>
                                                ))
                                                ) : (
                                                    <div className='loading-results-list'>
                                                        Sem resultados a serem exibidos.
                                                    </div>
                                                )
                                        }

                                    </div>
                                </div>
                            </Typography>
                        </Box>
                    </Modal>
                </div>
            </div>
            <div className="card-senado">
                <div class="box">
                    <img src={SenadoIcone} style={{ width: '40px', marginLeft: '25px' }} alt='' className='icone-cargo-senado'></img>
                    <b className="cards-b">{cargo?.senador?.eleitos}</b>
                    <button onClick={handleOpenSenado} disabled={cargo?.senador?.eleitos == 0}>Senado</button>
                    <Modal open={openSenado} onClose={handleCloseSenado} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                <img src={SenadoIcone} style={{ width: '40px', marginLeft: '25px' }} alt='' className='icone-cargo-senado'></img> Resultado Eleições Senado
                                <hr></hr>
                                <div className='container-resultados'>
                                    <div className='container-alunos-resultados'>
                                        {
                                            (props.resultados.length !== 0) ?
                                                (props.resultados.alunos_eleitos?.filter(filtro => filtro.cargo === "SENADOR").map(alunos => (
                                                    <ul>
                                                        <li className='li-profile-eleitos'>
                                                            <img className='cand-img' src={alunos.foto_url} alt='candidate'></img>
                                                        </li>
                                                        <li className='cand-bar-information'>
                                                            <h4>Apuração:</h4>
                                                            <Box sx={{ width: '100%' }}>
                                                                <Stack sx={{ color: '#38E069' }}>
                                                                    <LinearProgressWithLabel color="inherit" value={alunos.percentual_totalização} />
                                                                </Stack>
                                                            </Box>
                                                        </li>
                                                        <li className='cand-details'>
                                                            <b>{alunos.nome_urna}</b>
                                                            <b>{alunos.cargo}</b>
                                                            <br></br> {alunos.partido}</li>
                                                        <li className='cand-information'>{alunos.gênero >= "MASCULINO" ? (<BsGenderMale />) : alunos.gênero >= "FEMININO" ? (<BsGenderFemale />) : (<BsGenderTrans />)}
                                                            <BsCheck2Square color='#3A5EDD' />{alunos.raça}
                                                            <BsGeoAlt color='#3A5EDD' />{alunos.estado}
                                                            <FaBirthdayCake color='#3A5EDD' />{alunos.idade}
                                                            <Categoria categoria={alunos.categoria}></Categoria>
                                                        </li>
                                                        <li className='aluno-more-information'>
                                                            <BsListOl color='#3A5EDD' />{alunos.posição}º / {alunos.num_vagas_disputadas}
                                                            <Eleito resultado={alunos.resultado}></Eleito>
                                                            VOTOS {alunos.num_votos}
                                                            <b>{alunos.percentual_votos}%</b>
                                                        </li>
                                                    </ul>
                                                ))
                                                ) : (
                                                    <div className='loading-results-list'>
                                                        Sem resultados a serem exibidos.
                                                    </div>
                                                )
                                        }

                                    </div>
                                </div>
                            </Typography>
                        </Box>
                    </Modal>
                </div>
            </div>
            <div className="card-vice">
                <div class="box">
                    <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.559 0L11.0625 1.296L13.8495 1.719L15.1125 4.239L17.118 6.219L16.659 9L17.118 11.781L15.1125 13.761L13.8495 16.281L11.0625 16.704L8.559 18L6.0555 16.704L3.2685 16.281L2.0055 13.761L0 11.781L0.459 9L0 6.219L2.0055 4.239L3.2685 1.719L6.0555 1.296L8.559 0Z" fill="#3A5EDD" />
                        <path d="M2.55896 17.691V24L8.55896 22.5L14.559 24V17.691L11.532 18.15L8.55896 19.689L5.58596 18.15L2.55896 17.691Z" fill="#3A5EDD" />
                    </svg>

                    <b className="cards-b">{cargo?.vice_presidente?.eleitos}</b>
                    <button onClick={handleOpenVicePres} disabled={cargo?.vice_presidente?.eleitos == 0}>Vice-Presidente</button>
                    <Modal open={openVicePres} onClose={handleCloseVicePres} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.559 0L11.0625 1.296L13.8495 1.719L15.1125 4.239L17.118 6.219L16.659 9L17.118 11.781L15.1125 13.761L13.8495 16.281L11.0625 16.704L8.559 18L6.0555 16.704L3.2685 16.281L2.0055 13.761L0 11.781L0.459 9L0 6.219L2.0055 4.239L3.2685 1.719L6.0555 1.296L8.559 0Z" fill="#3A5EDD" />
                                    <path d="M2.55896 17.691V24L8.55896 22.5L14.559 24V17.691L11.532 18.15L8.55896 19.689L5.58596 18.15L2.55896 17.691Z" fill="#3A5EDD" />
                                </svg> Resultado Eleição Vice-presidente
                                <hr></hr>
                                <div className='container-resultados'>
                                    <div className='container-alunos-resultados'>
                                        {
                                            (props.resultados.length !== 0) ?
                                                (props.resultados.alunos_eleitos?.filter(filtro => filtro.cargo === "VICE PRESIDENTE").map(alunos => (
                                                    <ul>
                                                        <li className='li-profile-eleitos'>
                                                            <img className='cand-img' src={alunos.foto_url} alt='candidate'></img>
                                                        </li>
                                                        <li className='cand-bar-information'>
                                                            <h4>Apuração:</h4>
                                                            <Box sx={{ width: '100%' }}>
                                                                <Stack sx={{ color: '#38E069' }}>
                                                                    <LinearProgressWithLabel color="inherit" value={alunos.percentual_totalização} />
                                                                </Stack>
                                                            </Box>
                                                        </li>
                                                        <li className='cand-details'>
                                                            <b>{alunos.nome_urna}</b>
                                                            <b>{alunos.cargo}</b>
                                                            <br></br> {alunos.partido}</li>
                                                        <li className='cand-information'>{alunos.gênero >= "MASCULINO" ? (<BsGenderMale />) : alunos.gênero >= "FEMININO" ? (<BsGenderFemale />) : (<BsGenderTrans />)}
                                                            <BsCheck2Square color='#3A5EDD' />{alunos.raça}
                                                            <BsGeoAlt color='#3A5EDD' />{alunos.estado}
                                                            <FaBirthdayCake color='#3A5EDD' />{alunos.idade}
                                                            <Categoria categoria={alunos.categoria}></Categoria>
                                                        </li>
                                                        <li className='aluno-more-information'>
                                                            <BsListOl color='#3A5EDD' />{alunos.posição}º / {alunos.num_vagas_disputadas}
                                                            <Eleito resultado={alunos.resultado}></Eleito>
                                                            VOTOS {alunos.num_votos}
                                                            <b>{alunos.percentual_votos}%</b>
                                                        </li>
                                                    </ul>
                                                ))
                                                ) : (
                                                    <div className='loading-results-list'>
                                                        Sem resultados a serem exibidos.
                                                    </div>
                                                )
                                        }
                                    </div>
                                </div>
                            </Typography>
                        </Box>
                    </Modal>
                </div>
            </div>

            <div className="card-votos-totais">
                <div class="box">
                    <FaVoteYea color='#3A5EDD' size={20} />
                    <b className="cards-b">{apuracao.votos}</b> Votos totais</div>
            </div>
        </div>
    )
}

export default Cards;
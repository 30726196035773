import React from 'react';

// CSS
import './Description.scss';

// IMAGES
import TURMA2122 from '../../assets/TURMA2122.png';
import E2018 from '../../assets/E2018.png';
import NE2018 from '../../assets/NE2018.png';
import E2019 from '../../assets/E2019.png';
import NE2019 from '../../assets/NE2019.png';

function Description() {

    return (
        <div className="description-wrapper">
            <div className="description">
                <div className="description-boxes"><img src={E2018} style={{ width: '30px', height: '15px' }} alt='E2018'></img> Eleitos 2018</div>
                <div className="description-boxes"><img src={NE2018} style={{ width: '43px', height: '14px' }} alt='NE2018'></img> Não eleitos 2018</div>
                <div className="description-boxes"><img src={E2019} style={{ width: '46px', height: '14px' }} alt='E2019'></img> Eleitos 2019/2020</div>
            </div>
            <div className="description">
                <div className="description-boxes"><img src={NE2019} style={{ width: '54px', height: '14px' }} alt='NE2019'></img> Não eleitos 2019/2020</div>
                <div className="description-boxes"><img src={TURMA2122} style={{ width: '38px', height: '14px' }} alt='21/22'></img> Turma de 2021/2022</div>
            </div>
        </div>
    )
}

export default Description;
import React from 'react';
import './Header.scss';
import MARCA_RENOVABR_NEGATIVO from '../../assets/MARCA_RENOVABR_NEGATIVO.png';
import { Link } from 'react-router-dom';

function Header() {
    return (
        <header className='header'>
            <img src={MARCA_RENOVABR_NEGATIVO} style={{ width: '230px', marginTop: '-10px' }} alt='logo' className='logo-renovabr'></img>
            <h1>Sistema de Apuração</h1>
            <p><Link to="/">Tempo Real</Link></p>
            <p><Link to="/resultado">Resultado</Link></p>
        </header>
    )
}

export default Header; 
import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import './Subtitles.scss';
import Legenda from '../../assets/Legenda.PNG';

import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <Stack sx={{ color: '#38E069' }}>
                    <LinearProgress variant="determinate" {...props} />
                </Stack>
            </Box>
            <Box sx={{ minWidth: 10, size: 70, thickness: 6 }}>
                <Typography variant="body3" color="#00000">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

function Subtitles() {
    const [apuracao, setApuracao] = useState([]);
    const [progress, setProgress] = React.useState(0);

    useEffect(() => {
        api.get("/api/alunos/totais")
            .then((response) => {
                setApuracao(response.data)
            })
            .catch((err) => {
                console.error("Ocorreu um erro: " + err);
            });
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 0));
        }, 800);
        return () => {
            clearInterval(timer);
        };
    }, []);


    return (
        <div className='subtitles'>
            <div className='subtitles-details'>
                <h2>{apuracao.percent_totalização}% DE URNAS APURADAS</h2>
            </div>
            <div className="progress">
                <Box sx={{ width: '100%' }}>
                    <LinearProgressWithLabel color="inherit" value={apuracao.percent_totalização} />
                </Box>
            </div>
            <div className='legenda'>
                <img src={Legenda} style={{ width: '100%' }} alt='legenda'></img>
            </div>
        </div>
    )
}

export default Subtitles;